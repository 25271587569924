var poly = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      // "properties": {
      //     "stroke": "#097238",
      //     "stroke-width": 2,
      //     "stroke-opacity": 1,
      //     "fill": "#0f9d58",
      //     "fill-opacity": 0.5
      // },
      id: '1',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.441487550735474, 42.61628342953564],
            [23.44161629676819, 42.61510700370894],
            [23.44236731529236, 42.61524122791301],
            [23.446197509765625, 42.61502804815966],
            [23.44656229019165, 42.615067525946806],
            [23.446615934371945, 42.616062357921685],
            [23.4462833404541, 42.61607025334999],
            [23.446197509765625, 42.61704138339686],
            [23.44559669494629, 42.61701769747832],
            [23.445661067962646, 42.616859791124654],
            [23.444931507110596, 42.61669398902233],
            [23.44384789466858, 42.618178296890804],
            [23.442699909210205, 42.617823013653755],
            [23.442882299423218, 42.61730192790595],
            [23.442914485931393, 42.61703348809168],
            [23.442968130111694, 42.616907163072796],
            [23.443944454193115, 42.61712033639351],
            [23.44410538673401, 42.616907163072796],
            [23.441487550735474, 42.61628342953564],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        stroke: '#097238',
        'stroke-width': 2,
        'stroke-opacity': 1,
        fill: '#0f9d58',
        'fill-opacity': 0.5,
      },
      id: '2',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.446766138076782, 42.615051734834964],
            [23.44984531402588, 42.61472012056097],
            [23.44983458518982, 42.61515437699048],
            [23.44832181930542, 42.61513069035423],
            [23.448439836502075, 42.61595971726271],
            [23.448182344436642, 42.61597550814433],
            [23.44828963279724, 42.61655187258324],
            [23.448139429092407, 42.61655187258324],
            [23.4483003616333, 42.617294032633794],
            [23.44782829284668, 42.617420356867875],
            [23.44761371612549, 42.61656766331474],
            [23.446873426437378, 42.616607140125865],
            [23.446766138076782, 42.615051734834964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        stroke: '#0288d1',
        'stroke-width': 2,
        'stroke-opacity': 1,
        fill: '#1a90cb',
        'fill-opacity': 0.5,
      },
      id: '3',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.441766785408277, 42.614561106187836],
            [23.442054783293003, 42.61334894882637],
            [23.44202737825995, 42.61256237462979],
            [23.44398312807226, 42.61268359395376],
            [23.444077446112516, 42.612777505725575],
            [23.444082662197246, 42.61325160360997],
            [23.444103599754726, 42.61338573260676],
            [23.444050622708005, 42.613535197666664],
            [23.443878975403692, 42.61363726822998],
            [23.443686135604196, 42.61369242489343],
            [23.443535774941154, 42.613715736664375],
            [23.44341646432858, 42.61384406071255],
            [23.44350289528981, 42.61389963928181],
            [23.443105533057462, 42.614780716437025],
            [23.442981016230704, 42.615200962577404],
            [23.442366646156756, 42.615237363621105],
            [23.441618634544966, 42.615102697736404],
            [23.441766785408277, 42.614561106187836],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        stroke: '#0288d1',
        'stroke-width': 2,
        'stroke-opacity': 1,
        fill: '#1a90cb',
        'fill-opacity': 0.5,
      },
      id: '4',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.44310760498047, 42.61478328532072],
            [23.443504571914673, 42.61389897285411],
            [23.443418741226193, 42.61384370290803],
            [23.44353675842285, 42.613717371418495],
            [23.443686962127686, 42.6136936842357],
            [23.443880081176758, 42.61363841410741],
            [23.44405174255371, 42.61353576945333],
            [23.44410538673401, 42.61338575003924],
            [23.44408392906189, 42.61325152183608],
            [23.444137573242188, 42.61314098074568],
            [23.444931507110596, 42.61218952967711],
            [23.445918560028076, 42.61240666709315],
            [23.44556450843811, 42.61278172084687],
            [23.44974875450134, 42.61312518914531],
            [23.44983994960785, 42.61471617276136],
            [23.446760773658752, 42.615051734834964],
            [23.44655692577362, 42.615063578169234],
            [23.446181416511536, 42.61502015259924],
            [23.44298753403592, 42.61520004152953],
            [23.44310760498047, 42.61478328532072],
          ],
        ],
      },
    },
  ],
};

function initMap() {
  var uluru = {
    lat: 42.61540795170038,
    lng: 23.438289308540506,
  };

  const mapDOM = document.getElementById('map');
  if (!mapDOM) {
    return;
  }

  var map = new google.maps.Map(mapDOM, {
    zoom: 15,
    center: uluru,
  });

  map.data.addGeoJson(poly);

  var firstPoly = map.data.getFeatureById(1);
  var secondPoly = map.data.getFeatureById(2);
  var thirdPoly = map.data.getFeatureById(3);
  var fourthPoly = map.data.getFeatureById(4);

  var faza1 = {
    lat: 42.615764,
    lng: 23.443802,
  };

  var faza2 = {
    lat: 42.615663,
    lng: 23.447816,
  };

  var ltp = {
    lat: 42.613487,
    lng: 23.44299,
  };

  var faza3 = {
    lat: 42.613987,
    lng: 23.44499,
  };

  map.data.overrideStyle(firstPoly, {
    strokeColor: '#548f0e',
    fillColor: '#548f0e',
    fillOpacity: 0.5,
    strokeWeight: 2,
    strokeOpacity: 1,
  });

  map.data.overrideStyle(secondPoly, {
    strokeColor: '#548f0e',
    fillColor: '#548f0e',
    fillOpacity: 0.5,
    strokeWeight: 2,
    strokeOpacity: 1,
  });

  map.data.overrideStyle(thirdPoly, {
    strokeColor: '#CFBB7C',
    fillColor: '#CFBB7C',
    fillOpacity: 0.5,
    strokeWeight: 2,
    strokeOpacity: 1,
  });

  map.data.overrideStyle(fourthPoly, {
    strokeColor: '#0288d1',
    fillColor: '#1a90cb',
    fillOpacity: 0.5,
    strokeWeight: 2,
    strokeOpacity: 1,
  });

  function placeInfoWindow() {
    var infoWindow = new google.maps.InfoWindow();

    map.data.addListener('mouseover', function (e) {
      var f = e.feature.getId();

      if (f === '1') {
        infoWindow.setContent(
          '</div>' +
            '<img src="/static-media/images/lozen-park-tooltip.jpeg" style="width: 200px;" />' +
            '<h5 id="firstHeading" class="mt-2">Лозен парк <small>(Фаза 1)</small></h5>' +
            '<a href="https://www.residentialpark-lozen.com/en/" target="_blank" class="text-mint focus-none">Виж детайли <svg width="10" height="10" fill="var(--color-mint)"><use xlink:href="#icon-arrow-right"></use></svg></a>' +
            '</div>'
        );
        infoWindow.setPosition(faza1);
        infoWindow.open(map);
      }
      if (f === '2') {
        infoWindow.setContent(
          '</div>' +
            '<img src="/static-media/images/lozen-park-tooltip.jpeg" style="width: 200px;" />' +
            '<h5 id="firstHeading" class="mt-2">Лозен парк <small>(Фаза 2)</small></h5>' +
            '<a href="https://www.residentialpark-lozen.com/en/" target="_blank" class="text-mint focus-none">Виж детайли <svg width="10" height="10" fill="var(--color-mint)"><use xlink:href="#icon-arrow-right"></use></svg></a>' +
            '</div>'
        );
        infoWindow.setPosition(faza2);
        infoWindow.open(map);
      }
      if (f === '3') {
        infoWindow.setContent(
          '</div>' +
            '<img src="/static-media/images/gallery-image-3.jpeg" style="width: 200px;" />' +
            '<h5 id="firstHeading" class="mt-2">Лозен тех парк</h5>' +
            '</div>'
        );
        infoWindow.setPosition(ltp);
        infoWindow.open(map);
      }
      if (f === '4') {
        infoWindow.setContent(
          '</div>' +
            '<img src="/static-media/images/lozen-park-tooltip.jpeg" style="width: 200px;" />' +
            '<h5 id="firstHeading" class="mt-2">Лозен парк <small>(Фаза 3)</small></h5>' +
            '<a href="https://www.residentialpark-lozen.com/en/" target="_blank" class="text-mint focus-none">Виж детайли <svg width="10" height="10" fill="var(--color-mint)"><use xlink:href="#icon-arrow-right"></use></svg></a>' +
            '</div>'
        );
        infoWindow.setPosition(faza3);
        infoWindow.open(map);
      }
    });

    map.data.addListener('mouseout', function (e) {
      var f = e.feature.getId();

      if (f === '1') {
        etap = 'Фаза 1';
        infoWindow.setPosition(faza1);
        infoWindow.close(map);
      }
      if (f === '2') {
        etap = 'Фаза 2';
        infoWindow.setPosition(faza2);
        infoWindow.close(map);
      }
      if (f === '3') {
        etap = 'Лозен Тех Парк';
        infoWindow.setPosition(faza3);
        infoWindow.close(map);
      }
      if (f === '4') {
        etap = 'Фаза 3';
        infoWindow.setPosition(ltp);
        infoWindow.close(map);
      }
    });
  }

  placeInfoWindow();
}

window.onload = () => {
  initMap();
};
